<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent">
              <div class="text-muted text-center">
                <div class="px-6 text-wrap">
                  Bienvenue sur la plateforme de
                  <strong>Gestion de l'APDP</strong>
                </div>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <transition name="fade" mode="out-in">
                <form
                  v-if="transitionState === 'initial'"
                  key="initial"
                  role="form"
                  @submit.prevent="handleSubmit()"
                >
                  <div class="text-center text-muted mb-4">
                    <small>Connectez-vous ici</small>
                  </div>
                  <base-input
                    alternative
                    class="mb-3"
                    name="Email"
                    prepend-icon="ni ni-email-83"
                    placeholder="Email"
                    v-model="email"
                  />
                  <validation-error :errors="apiValidationErrors.email" />

                  <base-input
                    alternative
                    class="mb-3"
                    name="Password"
                    prepend-icon="ni ni-lock-circle-open"
                    type="password"
                    placeholder="Password"
                    v-model="password"
                  >
                  </base-input>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      :loading="isLoading"
                      :disabled="isLoading"
                      >Connexion</base-button
                    >
                  </div>
                </form>
                <form
                  v-if="transitionState === 'two_factor'"
                  key="two_factor"
                  role="form"
                  @submit.prevent="submit2FA()"
                >
                  <div class="text-center text-muted mb-4">
                    <small>Renseignez votre code ici</small>
                  </div>
                  <base-input
                    alternative
                    name="Code Validation"
                    prepend-icon="ni ni-lock-circle-open"
                    type="text"
                    placeholder="Code Validation"
                    v-model="code"
                  >
                  </base-input>
                  <validation-error :errors="apiValidationErrors.email" />
                  <div class="row mb-3">
                    <div class="col-6">
                      <a href="#" @click="resendCode" class="text-danger"
                        ><small>Envoyer à nouveau?</small></a
                      >
                    </div>
                  </div>

                  <div class="text-center">
                    <base-button
                      type="primary"
                      native-type="submit"
                      class="my-4"
                      :loading="isLoading"
                      :disabled="isLoading"
                      >Valider</base-button
                    >
                  </div>
                </form>
              </transition>
            </div>
          </div>
          <!-- <div class="row mt-3">
            <div class="col-6">
              <a href="#" class="text-light"
                ><small>Mot de Passe oublié?</small></a
              >
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

export default {
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data: () => ({
    email: "",
    password: "",
    code: null,
    isLoading: false,
    transitionState: "initial",
  }),
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      try {
        const { data } = await this.$store.dispatch("login", {
          user,
          requestOptions,
        });
        this.isLoading = false;
        if (data.success == undefined) {
          this.$notify({
            type: "danger",
            message: "Une erreur est survenue lors de l'authentification!",
          });
        } else if (data.success && data.data?.two_factor) {
          this.transitionState = "two_factor";
          this.$notify({
            type: "success",
            message: data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message: `${data.message}`,
          });
          this.setApiValidation(data.data.errors);
        }
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Une erreur est survenue lors de l'authentification!",
        });
        this.isLoading = false;
      }
    },
    async submit2FA() {
      this.isLoading = true;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
            code: this.code,
          },
        },
      };

      try {
        const { data } = await this.$store.dispatch("login2FA", {
          user,
          requestOptions,
        });
        this.isLoading = false;
        if (data.success == undefined) {
          this.$notify({
            type: "danger",
            message: "Une erreur est survenue lors de l'authentification!",
          });
        } else if (data.success) {
          this.$notify({
            type: "success",
            message: data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message: `${data.message}`,
          });
          this.setApiValidation(data.data.errors);
        }
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Une erreur est survenue lors de l'authentification!",
        });
        this.isLoading = false;
      }
    },
    async resendCode() {
      this.isLoading = true;
      const user = {
        data: {
          type: "token",
          attributes: {
            email: this.email,
            password: this.password,
          },
        },
      };

      try {
        const { data } = await this.$store.dispatch("resendCode", {
          user,
          requestOptions,
        });
        this.isLoading = false;
        if (data.success == undefined) {
          this.$notify({
            type: "danger",
            message: "Une erreur est survenue lors de l'envoie du code de vérification!",
          });
        } else if (data.success) {
          this.$notify({
            type: "success",
            message: data.message,
          });
        } else {
          this.$notify({
            type: "danger",
            message: `${data.message}`,
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Une erreur est survenue lors de l'envoie du code de vérification!",
        });
        this.isLoading = false;
      }
    },
  },
};
</script>
